//https://gist.github.com/danharper/ad6ca574184589dea28d

export class CancellationToken {
  public cancelled: boolean;

  constructor() {
    this.cancelled = false;
  }

  throwIfCancelled() {
    if (this.isCancelled) {
      throw 'Cancelled!';
    }
  }

  get isCancelled() {
    return this.cancelled === true;
  }

  public cancel() {
    this.cancelled = true;
  }

  // could probably do with a `register(func)` method too for cancellation callbacks
}

export class CancellationTokenSource {
  public token: CancellationToken;
  constructor() {
    this.token = new CancellationToken();
  }

  cancel() {
    this.token.cancel();
  }
}
